const axios = window.axios
const url = '/api/v2/user-logs/'



export const ActivityApiUrls = {
  // журнал действий
  async getActivity(params) {
    return (await axios.get(url, {
      params: params
    }));
  },
};