<template>
  <div class="px-4 pt-3">
    <v-card>
      <v-row class="px-4">
        <v-col cols="6" sm="3">
          <v-menu
            ref="from"
            v-model="fromDateDialog"
            :close-on-content-click="false"
            :return-value.sync="from"
            transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedFrom"
                label="С:"
                readonly
                v-bind="attrs"
                prepend-icon="mdi-calendar-blank-multiple"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="from"
              no-title
              :max="now"
              scrollable
              locale="ru"
              :first-day-of-week="1"
            >
              <v-spacer />
              <v-btn text color="primary" @click="fromDateDialog = false">
                Отмена
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  fromDateDialog = false;
                  $refs.from.save(from);
                  loadRow();
                "
              >
                Применить
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="6" sm="3">
          <v-menu
            ref="to"
            v-model="toDateDialog"
            :close-on-content-click="false"
            :return-value.sync="to"
            transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedTo"
                label="По:"
                readonly
                v-bind="attrs"
                prepend-icon="mdi-calendar-blank-multiple"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="to"
              no-title
              scrollable
              locale="ru"
              :max="now"
              :first-day-of-week="1"
            >
              <v-spacer />
              <v-btn text color="primary" @click="toDateDialog = false">
                Отмена
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  toDateDialog = false;
                  $refs.to.save(to);
                  loadRow();
                "
              >
                Применить
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="6" sm="3">
          <v-select
            :items="itemsEmailArray"
            label="Сотрудник(логин)"
            v-model="email"
            item-text="email"
            item-value="id"
            @change="loadRow"
          ></v-select>
        </v-col>

        <v-col cols="6" sm="3">
          <v-text-field
            label="Поиск по брони"
            type="number"
            v-model="booking_model"
            @change="loadRow"
          >
          </v-text-field>
        </v-col>
      </v-row>
    </v-card>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        :options.sync="options"
        :server-items-length="totalItems"
      >
        <template v-slot:[`item.created_at`]="{ item }">
          {{ formatDate(item.created_at) }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import moment from "moment";
import { ActivityApiUrls } from "@/services/accommodationRequests/activity.api.js";
import { UsersApiUrls } from "@/services/accommodationRequests/user.api.js";

export default {
  data: () => ({
    firstWatcherTrigger: false,
    totalItems: 0,
    options: {},
    email: null,
    fromDateDialog: false,
    toDateDialog: false,
    items: [],
    booking_model: null,
    itemsEmailArray: [],
    now: new Date(moment(new Date()).add(1, "day")).toISOString().substr(0, 10),
    to: new Date(moment(new Date()).add(1, "day")).toISOString().substr(0, 10),
    from: new Date().toISOString().substr(0, 10),
    loading: false,
    headers: [
      { text: "№ Брони", value: "booking_number", class: "table-title" },
      { text: "Сотрудник", value: "user", class: "table-title" },
      { text: "Дата", value: "created_at", class: "table-title" },
      { text: "Действие", value: "action", class: "table-title" }
    ]
  }),
  computed: {
    computedFrom() {
      if (this.from) return moment(this.from).format("DD.MM.YYYY");
      return "";
    },
    computedTo() {
      if (this.to) return moment(this.to).format("DD.MM.YYYY");
      return "";
    }
  },
  watch: {
    options: {
      handler() {
        if (!this.firstWatcherTrigger) {
          this.firstWatcherTrigger = true;
          return;
        }
        this.loadRow();
      },
      deep: true
    }
  },
  mounted() {
    this.loadRow();
    this.loadEmails();
  },
  methods: {
    async loadRow() {
      this.loading = true;
      let params = {
        "page[number]": this.options.page || 1,
        "page[size]": this.options.itemsPerPage || 10,
        user_id: this.email,
        start: new Date(this.from).getTime() / 1000,
        end: new Date(this.to).getTime() / 1000
      };
      if (this.booking_model) params.search = this.booking_model;
      try {
        let resp = await ActivityApiUrls.getActivity(params);
        this.totalItems = resp.data.meta.pagination.count;
        this.items = resp.data.results;
      } finally {
        this.loading = false;
      }
    },
    async loadEmails() {
      this.itemsEmailArray = await UsersApiUrls.getUsers();
      this.itemsEmailArray.unshift({
        email: "Все",
        id: null
      });
    },
    formatDate(date) {
      return moment(date * 1000).format("DD.MM.YYYY HH:mm");
    }
  }
};
</script>

<style></style>
